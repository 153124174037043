<script lang="ts" setup></script>

<template>
  <div class="flex h-full justify-center lg:hidden">
    <NuxtLink
      to="/"
      class="relative flex h-full w-10 items-center justify-center rounded-lg text-white"
    >
      <Icon name="home" class="self-center" />
    </NuxtLink>

    <NavigationSelectCompanyDropdown class="w-full max-w-[calc(100vw-192px)]" />
  </div>
</template>

<style lang="postcss" scoped>
:deep(.multiselect__placeholder) {
  @apply whitespace-nowrap text-base;
}
:deep(.multiselect__select) {
  @apply w-3 px-0;
}

:deep(.multiselect__tags) {
  @apply pl-0 pr-3.5;
}

:deep(.multiselect__content-wrapper) {
  @apply -left-10 w-[calc(100vw-36px)];
}
</style>
